<template>
  <div id="main" class="main__ukraine" :class="{ loaded: loaded}">
    <div class="map-zone__controls">
      <div class="container">
        <a href="#" @click.prevent="isFilter=!isFilter">Фільтри</a>
      </div>        
    </div>

    <div class="map-zone container"
      :class="{'show-filter': isFilter, 'show-mapinfo': isMapInfo}" 
    >      
      <div v-show="!loaded" class="loading">loading......</div>

      <div class="map-ukr__inner map__inner"
          :class="{'waiting' : !loaded}"
          @click="isFilter=false;" 
      >
        <mapUkr 
          :targetLink="'/regions/'"
          :regionsData="regionsData"
          />
      </div>

      <div class="map-zone__toolbar">
        <mapTabs />
      </div>

    </div><!-- map-zone -->

    <billboard />  

    <div class="grid-zone container">
      <core-grid
        :dataList="table.gridData"
        :columns="table.gridColumns"
        :filter-key="table.searchQuery"
        :url="'/fuels/'"
        :routerLink="true"
      />
      <core-sidebar />
    </div>

  </div>
</template>

<script>
  import mapUkr from './../../components/body/map-ukr.vue';
  import mapTabs from './../../components/body/map-tabs.vue';
  import billboard from './../../components/body/billboard.vue';

  import axios from 'axios';

  const convert = require('xml-js');

  export default {
    name: 'Home',
    metaInfo() {
      return {
        titleTemplate: '%s - Головна',
        meta: [
          // { name: 'description', content:  'Середні ціни на бензин, дизельне паливо і скраплений газ на АЗС України. Достовірна інформація про роздрібному ринку нафтопродуктів України. Найповніший охоплення брендів і марок палива.'},
          { property: 'og:title', content: "АЗС України - Середні ціни на бензин по Украіні"},
          // { property: 'og:site_name', content: 'АЗС України'},
          // {property: 'og:type', content: 'website'},    
          // {name: 'robots', content: 'index,follow'} 
        ]
      }
    },
    data: () => ({
      loaded: false,
      table: {
        searchQuery: '',
        gridColumns: [
          { 
            label: '',
            name: 'name'
          }
        ],
        gridData: []
      },
      getDataXml: '/assets/data/getData.xml',
      getGridDataURL: '/assets/data/getGridDataJSON.pl',
      isFilter: false,
      isMapInfo: false
    }),
    computed: {
      regionsData () {
        return this.$store.getters.getRegionsData[0];
      }
    },
    components: {
      mapUkr,
      mapTabs,
      billboard
    },
    created() {
      axios
        .get('/assets/data/main.json')
        .then(response => {
          this.getDataXml = response.data.getDataXml;
          this.getGridDataURL = response.data.getGridDataJSON;
          //MapData
          this.updateMapData();
          this.tableUpdate();

        })
        .catch(error => {
          console.log(error);
          this.errors.push(error)
        });
    },
    methods: {
      updateMapData(params) {
        params = params ? params : this.$store.getters.getFuelSelected;
        let paramsStr = params && params.length ? '?postData=' + params.join("|") : '';
        this.loaded = false;

        axios
          //.get('http://azs.uapetrol.com/cgi-bin/getData.pl' + paramsStr)
          .get(this.getDataXml + paramsStr)
          .then(response => {
            var jsonStr = convert.xml2json(response.data, {compact: true});
            var json = JSON.parse(jsonStr);
            var regArr = json.map.item;
            var ukraine = {};

            for(let i = 0; i < regArr.length; i++){
              ukraine[regArr[i]._attributes.id] = regArr[i]._attributes;
            }

            const region = {
              idReg: 0,
              data: ukraine
            }
            this.$store.dispatch('addRegionData', region);         
          })
          .catch(error => {
            this.errored = true;
            console.log(error);
          })
          .finally(
            () => {
              this.loaded = true;
            }      
          );
      },
      tableUpdate() {
      //Table
        axios
          .get(this.getGridDataURL)
          .then(response => {
            this.table.gridColumns = [
              {
                label: 'Марка палива',
                name: 'mark'            
              },
              {
                label: response.data.dates.curr,
                name: 'curr'
              },
              {
                label: response.data.dates.prev,
                name: 'prev'            
              },
              {
                label: 'зміна, коп/л',
                name: 'change'            
              },
              {
                label: 'регіонів',
                name: 'oblUp'            
              },
              {
                label: 'регіонів',
                name: 'oblDown'            
              },
              {
                label: 'регіонів',
                name: 'oblNochange'            
              }           
            ];
            this.table.gridData = response.data.data.items;
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      }
    }
  }
</script>
